import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './assets/css/main.css';
import './assets/css/additional.css';
import { Footer } from './sections/footer.js';
import { About } from './sections/about.js';
import { Available } from './sections/available.js';
import { CheckAllMls } from './sections/check-all-mls.js';
import { ScrollToTop } from './sections/scroll-to-top.js';
import { websiteData } from './data.js';
import { RecentlySold } from './sections/recently-sold.js';
import { PageNotFound } from './pages/page-not-found.js';
import { Property } from './pages/property.js';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import smoothscroll from 'smoothscroll-polyfill';


export default function App(){
    const [soldData, setSoldData] = useState(null);
    const [loadingSoldData, setLoadingSoldData] = useState(true);
    const [soldError, setSoldError] = useState(null);

    const [availableData, setAvailableData] = useState(null);
    const [loadingAvailableData, setLoadingAvailableData] = useState(true);
    const [availableError, setAvailableError] = useState(null);

    useEffect(() => {
        document.title = `${websiteData.short_title} | Home`
        smoothscroll.polyfill();

        axios("/api/properties/sold")
            .then((response) => {
                setSoldData(response.data)
            })
            .catch((error) => {
                console.log("Error fetching data: ", error);
                setSoldError(error)
            })
            .finally(() => {
                setLoadingSoldData(false);
            })
        
        axios("/api/properties/available")
            .then((response) => {
                setAvailableData(response.data)
            })
            .catch((error) => {
                console.log("Error fetching data: ", error);
                setAvailableError(error)
            })
            .finally(() => {
                setLoadingAvailableData(false);
            })
    }, []);

    return (
        <Router>
            <ScrollToTop>
            <Routes>
                <Route path="*" element={<PageNotFound />} />
                <Route index path="/" element={ 
                    <Home 
                        loadingSoldData={loadingSoldData} 
                        loadingAvailableData={loadingAvailableData} 
                        soldData={soldData} 
                        availableData={availableData}
                        soldError={soldError}
                        availableError={availableError}
                    />} 
                />
                <Route path="/property/:listingId" element={<Property />} />
            </Routes>
            <Footer/>
            </ScrollToTop>
        </Router>
    );
}

function Home(props) {
    
    let loadingSoldData = props.loadingSoldData;
    let loadingAvailableData = props.loadingAvailableData;
    let soldError = props.soldError
    let availableError = props.availableError
    let soldData = props.soldData
    let availableData = props.availableData
        
    return (
        <>
        <Banner />
        <ShowAvailable loadingAvailableData={loadingAvailableData} availableError={availableError} properties={ availableData }/>
        <About />
        <CheckAllMls />
        <ShowRecentlySold loadingSoldData={loadingSoldData} soldError={soldError} properties={ soldData }/>
        </>
    )
    
}
function ShowAvailable(props) {
    let loadingAvailableData = props.loadingAvailableData;
    let availableError = props.availableError
    
    if (loadingAvailableData) {
        return (<></>)
    } else if (availableError !== null) {
        console.log(availableError)  
        return (<></>)
    } else {
        let properties = props.properties

        return (
            <Available properties={properties} />
        )
    }
}

function ShowRecentlySold(props) {
    let loadingSoldData = props.loadingSoldData;
    let soldError = props.soldError
    
    if (loadingSoldData) {
        return (<></>)
    } else if (soldError !== null) {
        console.log(soldError)  
        return (<></>)
    } else {
        let properties = props.properties

        return (
            <RecentlySold properties={properties} />
        )
    }
}

function Banner(props) {
    return(
        <section className="banner onload-image-fade-in onload-content-fade-right style5 fullscreen content-align-center image-position-center invert">
            <div className="content">

                <h1><img className="banner-logo" src="/images/lobo-logo-white.png" alt="Logo" /></h1>
                <p className="major">{websiteData.tagline}</p>
                <ul className="actions stacked">
                    <li><HashLink smooth="true" to="/#first" className="button big wide">Find Your Home</HashLink></li>
                </ul>
            </div>
            <div className="image">
                <img src="/images/banner.jpg" alt="Website Banner" />
            </div>
        </section>
    )
}

