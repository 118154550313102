export const websiteData = {
    "year" : "2022",
    "title" : "LoBo Realty Incorporated",
    "short_title" : "LoBo Realty",
    "tagline" : "Proudly serving the Tampa Bay Area",
    "owner" : "Dean LoBianco",
    "owner_title" : "Owner of LoBo Realty Inc.",
    "about_desc" : "I started my career in Real Estate in 1987 in San Diego, California. I am a licensed Real Estate Broker and established LoBo Realty Inc. in 2005. LoBo Realty Inc. is a full service Real Estate company specializing in residential sales in the Tampa Bay Area.",
    "facebook_link" : "https://www.facebook.com/loborealty",
    "phone_number" : "727-647-0239",
    "email_address" : "loborealtyinc@gmail.com",
    "contact_tagline" : "Let's find your next home. Call or email to reach out!",
    "available_none_title" : "Sorry, no currently active listings.",
    "available_none_desc" : "Currently we have no active listings on our website, but please feel free to contact us so we can help you find your new home!",
    "developer_link" : "https://www.linkedin.com/in/asterv/",
    "check_mls_title" : "Can't find what you're looking for?",
    "check_mls_desc" : "Click the button below to search through all public listings",
    "check_mls_button" : "Search All MLS Listings",
    "all_mls_link" : "https://loborealty.mfr.mlsmatrix.com/Matrix/Public/?L=1&ap=SCH"
}