import React from 'react';
import { websiteData } from '../data.js';

export function About(props) {
    return (
        <section className="spotlight onscroll-image-fade-in style2 content-align-left image-position-center orient-left">
            <div className="content">
                <div className="header">
                    <h2>{ websiteData.owner }</h2>
                    <p>{ websiteData.owner_title }</p>

                </div>
                <p>{ websiteData.about_desc }</p>

                <ul className="actions stacked">
                    <li><a href={`tel:` + websiteData.phone_number } className="button">Contact Dean</a></li>
                </ul>
            </div>
            <div className="image">
                <img src="images/dlobianco.jpg" alt="" />
            </div>
        </section>
    );
}