import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { websiteData } from '../data.js';


export function PageNotFound(props) {
    useEffect(() => {
        document.title = `${ websiteData.title } | 404 Page Not Found!`
    })
    return (
        <section className="banner onload-image-fade-in onload-content-fade-center style5 fullscreen image-position-center color1 invert content-align-left">
            <div className="content">
                <h2>Sorry, the page you are looking for cannot be found!</h2>
                <ul className="actions stacked">
                    <li><Link to="/">Go to Home Page</Link></li>
                </ul>
            </div>
            <div className="image">
                <img src="/images/banner.jpg" alt="Website Banner" />
            </div>
        </section>
    );
}