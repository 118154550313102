import React from 'react';
import { websiteData } from '../data.js';
import { formatter } from './recently-sold.js';
import { Link } from 'react-router-dom';

export function Available(props) {
    return (
        <ShowAvailable properties={props.properties} availableListingsExist={props.properties ? true : false }/>
    );
}

function ShowAvailable(props) {
    const availableListingsExist = props.availableListingsExist;

    if (availableListingsExist) {
        return (
            <AvailablePropertiesList properties={props.properties}/>
        )
    } else {
        return (
            <section className="spotlight onscroll-image-fade-in style5 orient-left content-align-left image-position-center halfscreen" id="first">
                <div className="content">
                    <h2>{ websiteData.available_none_title }</h2>
                    <p>{ websiteData.available_none_desc }</p>
                    <ul className="actions stacked">
                        <li><a href={`tel:` + websiteData.phone_number } className="button">Contact Us</a></li>
                    </ul>
                </div>
                <div className="image">
                    <img src="images/spotlight01.jpg" alt="No available listings background" />
                </div>
            </section>
        )
    }
}

function AvailablePropertiesList(props) {
    const properties = props.properties;
    const availableProperties = Object.keys(properties)
        .map((key, index) => {
            const imagePath = `https://storage.googleapis.com/loborealty-bebdc.appspot.com/${key}/${key}-0.jpeg`;
            const cityState = `${properties[key].City}, ${properties[key].StateOrProvince}`;

            let spotlightClass = ""
            let spotlightId = ""

            if (index % 2 === 0) {
                spotlightClass = "spotlight style1 orient-right content-align-left image-position-center onscroll-image-fade-in"
            } else {
                spotlightClass = "spotlight style1 orient-left content-align-left image-position-center onscroll-image-fade-in"
            }
            if (index === 0) {
                spotlightId = "first"
            }
            return (
                <section key={key} className={spotlightClass} id={spotlightId}>
                    <div className="content">
                        <header>
                            <h2>{`${properties[key].UnparsedAddress}, ${cityState}`}</h2>
                            <ListingDetails property={properties[key]} propertyType={properties[key].PropertyType}/>
                        </header>
                        <p>{truncate(properties[key].PublicRemarks, 400)}</p>
                        <ul className="actions stacked">
                            <li><Link to={`/property/${key}`} className="button">View Details</Link></li>
                        </ul>
                    </div>
                    <div className="image">
                        <img src={ imagePath } alt="" />
                    </div>
                </section>
            )
        })
    return (availableProperties);
}

export function ListingDetails(props) {
    const property = props.property;
    const propertyType = props.propertyType;

    if (propertyType === 'Land') {
        return (
            <p>
                {`
                ${property.MlsStatus} | 
                ${formatter.format(property.ListPrice)} | 
                ${property.LotSizeAcres} Acres
                `}
            </p>
        )
    } else if (propertyType === 'Residential'){
        return (
            <p>
                {`
                ${property.MlsStatus} | 
                ${formatter.format(property.ListPrice)} | 
                ${property.LivingArea} ${property.LivingAreaUnits} | 
                ${property.BedroomsTotal} bedrooms ${property.BathroomsTotalInteger} bathrooms
                `}
            </p>
        )
    } else {
        return (
            <p>
                {`
                ${property.MlsStatus} | 
                ${formatter.format(property.ListPrice)} | 
                ${property.LivingArea} ${property.LivingAreaUnits}
                `}
            </p>
        )
    }
}

function truncate( str, n, useWordBoundary ){
    if (str.length <= n) { return str; }
    const subString = str.substr(0, n-1); // the original check
    return (useWordBoundary 
      ? subString.substr(0, subString.lastIndexOf(" ")) 
      : subString) + "...";
};