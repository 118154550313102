import React from 'react';
import { websiteData } from '../data.js';


export function CheckAllMls(props) {
    return (
        <section className="spotlight onscroll-image-fade-in style5 orient-center content-align-center image-position-center halfscreen" id="first">
            <div className="content">
                <h2>{ websiteData.check_mls_title }</h2>
                <ul className="actions stacked">
                    <li><a href={ websiteData.all_mls_link } className="button" target="_blank" rel="noopener noreferrer">{websiteData.check_mls_button}</a></li>
                </ul>
            </div>
            <div className="image">
                <img src="images/spotlight02.jpg" alt="No available listings background" />
            </div>
        </section>
    );
}