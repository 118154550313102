import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { websiteData } from '../data.js';
import { ListingDetails  } from '../sections/available.js';
import { SRLWrapper } from "simple-react-lightbox";

export function Property(props) {
    let params = useParams();

    const [property, setProperty] = useState(null);
    const [propertyLoading, setPropertyLoading] = useState(true);
    const [propertyError, setPropertyError] = useState(null);
    
    const [propertyImages, setPropertyImages] = useState([null]);
    const [propertyImagesLoading, setPropertyImagesLoading] = useState(true);
    const [propertyImagesError, setPropertyImagesError] = useState(null);
    
    useEffect(() => {
        document.title = `${websiteData.short_title} | Listing Details`
       
        axios(`/api/${params.listingId}`)
            .then((response) => {
                setProperty(response.data)
            })
            .catch((error) => {
                console.log("Error fetching data: ", error);
                setPropertyError(error);
            })
            .finally(() => {
                setPropertyLoading(false);
            })
        
        axios(`/api/${params.listingId}/images`)
            .then((response) => {
                setPropertyImages(response.data)
            })
            .catch((error) => {
                console.log("Error fetching data: ", error);
                setPropertyImagesError(error);
            })
            .finally(() => {
                setPropertyImagesLoading(false);
            })
        
    }, [params.listingId]);
    return (
        <PropertyContents 
            propertyLoading={propertyLoading} 
            propertyError={propertyError} 
            property={property}
            propertyImagesLoading={propertyImagesLoading}
            propertyImagesError={propertyImagesError}
            propertyImages={propertyImages}
        />
    );
}

function PropertyContents(props) {
    let propertyLoading = props.propertyLoading
    let propertyError = props.propertyError 
    let propertyImagesLoading = props.propertyImagesLoading;
    let propertyImagesError = props.propertyImagesError;
    
    if (propertyLoading) {
        return (
            <section className="wrapper style1 align-center">
                <div className="inner">
                    <h2>Loading Property Details</h2>
                    <div className="loading">
                        <div className="loading-dot"></div>
                        <div className="loading-dot"></div>
                        <div className="loading-dot"></div>
                    </div>                
                </div>
            </section>
            
        )
    } else if (propertyError !== null) {
        console.log(propertyError) 
        return (<></>)
    } else {
        let property = props.property
        let propertyImages = props.propertyImages

        return (
            <>
            <section className="spotlight onscroll-image-fade-in style4 content-align-left image-position-center orient-center halfscreen" id="first">
                <div className="content">
                    <h2>{`${property.UnparsedAddress}, ${property.City}, ${property.StateOrProvince}`}</h2>
                    <ListingDetails property={property} propertyType={property.PropertyType}/>
                    <ul className="actions">
                        <li><Link to="/" className="button primary small">Contact Dean</Link></li>
                        <li><a href={`https://maps.google.com/?q=${property.UnparsedAddress}, ${property['City']}, ${property.StateOrProvince}, ${property['PostalCode']}`} target="_blank" rel="noreferrer" className="button small">Open in Maps</a></li>
                        <li><Link to="/" className="button small">Back to Home Page</Link></li>
                    </ul>
                </div>
                <div className="image">
                    <img src={`https://storage.googleapis.com/loborealty-bebdc.appspot.com/${property['ListingKey']}/${property['ListingKey']}-0.jpeg`} alt="property banner" />
                </div>
            </section>
            
            <section className="wrapper style1 align-center">
                <div className="inner">
                    <div className="index align-left">
                        <section>
                            <header>
                                <h3>Description</h3>
                            </header>
                            <div className="content">
                                <p>{property.PublicRemarks}</p>
                            </div>
                        </section>
                    </div>
                    
                    <ShowFeatures property={property} propertyType={property.PropertyType} />
                    <ShowGallery propertyImagesLoading={propertyImagesLoading} propertyImagesError={propertyImagesError} propertyImages={propertyImages}/>
                </div>
            </section>

            </>
        )
    }
}

function ShowFeatures(props) {
    let propertyType = props.propertyType;
    let property = props.property;

    if (propertyType === 'Land') {
        return(
            <></>
        )
    } else {
        return (
            <>
            <hr />
                <h2>Features</h2>
                <div className="items onscroll-fade-in style3 small">
                    <section>
                        <span className="icon style2 major solid fa-fan"></span>
                        <p>{property.Cooling}</p>
                    </section>
                    <section>
                        <span className="icon solid style2 major fa-border-all"></span>
                        <p>{property.Flooring}</p>
                    </section>
                    <section>
                        <span className="icon solid style2 major fa-calendar"></span>
                        <p>{`Built in ${property.YearBuilt}`}</p>
                    </section>
                    <section>
                        <span className="icon solid style2 major fa-couch"></span>
                        <p>{`Interior includes ${property.InteriorFeatures}`}</p>
                    </section>
                </div>
            </>
        )
    }
}

function ShowGallery(props) {
    let propertyImagesLoading = props.propertyImagesLoading;
    let propertyImagesError = props.propertyImagesError;

    if (propertyImagesLoading) {
        return(<></>)
    } else if (propertyImagesError !== null) {

        return(<>{console.log(propertyImagesError)}</>)
    } else {
        let propertyImages = props.propertyImages
        return (
            <>
                <hr />
                <h2>Photo Gallery</h2>
                <br/>
                <SRLWrapper>
                    <GalleryImages propertyImages={propertyImages} />
                </SRLWrapper>
            </>
        )
    }
}

function GalleryImages(props) {
    const propertyImages = props.propertyImages

    const galleryImages = propertyImages.map((image, key) => {
        return (
            <article key={`img-${key}`}>
                <a href={image} >
                    <img src={image} alt="" title="" className="image" id="property-image"/>
                </a>
            </article>
        )
    })
    return (
        <div className="gallery onscroll-fade-in style1 small">
            { galleryImages }
        </div>
    )
}