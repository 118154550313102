import React from 'react';
import { websiteData } from '../data.js';
import { Link } from 'react-router-dom';

export function Footer(props) {
    return (
        <footer className="wrapper style1 align-center">
            <div className="inner">
                <h3>Contact Information</h3>
                <ul className="icons">
                    <li><a href={ websiteData.facebook_link } target="_blank" rel="noreferrer" className="icon brands style2 fa-facebook-f"><span className="label">Facebook</span></a></li>
                    <li><a href={`mailto:` + websiteData.email_address } className="icon style2 fa-envelope"><span className="label">Email</span></a></li>
                    <li><a href={`tel:` + websiteData.phone_number } className="icon solid style2 fa-phone"><span className="label">Call</span></a></li>
                </ul>
                <p>
                    Phone Number: <a href={`tel:` + websiteData.phone_number }>{websiteData.phone_number}</a>
                    <br/>
                    Email: <a href={`mailto:` + websiteData.email_address }>{websiteData.email_address}</a>
                    <br/>
                    <Link to="/">Return to Home Page</Link>
                    <br/><br/>
                    &copy; { `${websiteData.year} ${websiteData.title}` } | Web Development by <a href={ websiteData.developer_link }>Aster Valdemoro</a>
                </p>
            </div>
        </footer>
    );
}