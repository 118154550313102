import React from 'react';

export function RecentlySold(props) {
    return (
        <section className="wrapper style1 align-center">
            <div className="inner">
                <h2>Recently Sold Listings</h2>
            </div>
            <RecentlySoldList properties={props.properties} />

        </section>

    );
}

export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0, 
  minimumFractionDigits: 0, 
});

function RecentlySoldList(props) {
    const properties = props.properties;
    const recentlySoldProperties = Object.keys(properties)
        .sort((a,b) => {
            let aDate = properties[a].CloseDate;
            let bDate = properties[b].CloseDate;
            
            let sort = 1; 

            if (aDate < bDate) { sort = 0 } 
            if (aDate > bDate) { sort = -1 }

            return sort
        })
        .map((key) => {
            const imagePath = `https://storage.googleapis.com/loborealty-bebdc.appspot.com/${key}/${key}-0.jpeg`;
            const date = new Date(properties[key].CloseDate)
            const cityState = `${properties[key].City}, ${properties[key].StateOrProvince}`;

            return (
                <article key={key}>
                    <img className="image" src={ imagePath } alt="" />
                    <div className="caption">
                        <h3>
                            { properties[key].UnparsedAddress }<br/>
                            { cityState }
                        </h3>
                        <p>
                            { `Close Price: ${formatter.format(properties[key].ClosePrice)}`}
                            <br/>
                            { `Sold: ${date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}`}
                        </p>
                    </div>
                </article>
            )
        })
    return (
        <div className="gallery lightbox onscroll-fade-in style1 medium">
            {recentlySoldProperties}
        </div>
    );
}
